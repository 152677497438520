import { memo, useContext, useEffect, useRef, useState } from "react";
import styles from "./Library.css";
import classes from "classnames";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { get } from "@churchofjesuschrist/universal-env";
import Frame from "../../components/Frame";
import LibraryHeader from "../../components/LibraryHeader";
import ErrorPanel from "../../components/ErrorPanel";
import GeneralContext from "../../components/GeneralContext";
import LibraryGridItems from "../../components/LibraryGridItems";
import Mask from "../../components/Mask";
import Notifications from "../../components/Notifications";
import SidePanelManager from "../../components/SidePanelManager";
import PanelPlatformFooter from "../../components/PanelPlatformFooter";
import ScrollLogic from "../../../util/template-component-scroll-utils";
import { useMountEffect } from "../../../util/custom-hooks";

const { APP_URL } = get();

export const Library = (props) => {
    const {
        activePanel,
        lang,
        library: {
            noIndex,
            breadCrumbs,
            canonicalUrl,
            fallbackEntryImage,
            sections,
            status,
            title,
        },
        notifications,
        setActivePanel,
        showArchivedContent,
    } = props;
    const { crossLinkMode } = useContext(GeneralContext);

    let toggleSidePanel = (panel) => {
        activePanel === panel ? setActivePanel(null) : setActivePanel(panel);
    };

    const [forceMobile, setForceMobile] = useState(false);
    const [allowMask, setAllowMask] = useState(false);
    // We should only remove archived-content from sections after the browser has hydated the server rendering,
    //   so we wait for the client rendering to conditionally remove the collection
    const [isClient, setIsClient] = useState(false);
    const [collectionSections, setCollectionSections] = useState(sections);

    const scrollUtils = useRef();
    const defaultPanelRef = useRef();
    const history = useHistory();

    useMountEffect(() => {
        setIsClient(true);

        // activePanel mediaQueryList
        const footerMediaQueryList = window.matchMedia(
            "(min-width: 60em) and (max-width: 80em)"
        );
        const allowMaskMediaQueryList = window.matchMedia("(max-width: 60em)");

        setForceMobile(footerMediaQueryList.matches);
        setAllowMask(allowMaskMediaQueryList.matches);

        const handleFooterMediaQueryChange = (event) => {
            setForceMobile(event.matches);
        };
        const handleAllowMaskMediaQueryChange = (event) => {
            setAllowMask(event.matches);
        };

        footerMediaQueryList.addEventListener(
            "change",
            handleFooterMediaQueryChange
        );
        allowMaskMediaQueryList.addEventListener(
            "change",
            handleAllowMaskMediaQueryChange
        );

        return () => {
            footerMediaQueryList.removeEventListener(
                "change",
                handleFooterMediaQueryChange
            );
            allowMaskMediaQueryList.removeEventListener(
                "change",
                handleAllowMaskMediaQueryChange
            );
        };
    });

    useMountEffect(() => {
        scrollUtils.current = new ScrollLogic({
            history,
            defaultPanelRef,
            crossLinkMode,
        });

        return () => {
            scrollUtils.current?.cleanup();
        };
    });

    useEffect(() => {
        if (allowMask && activePanel) {
            scrollUtils.current?.scrollHeaderOffScreen();
        }
    }, [allowMask, activePanel]);

    useEffect(() => {
        if (isClient && !showArchivedContent) {
            // filter out archived-content
            setCollectionSections(
                sections?.map((section) => {
                    const filteredEntries = section?.entries?.filter(
                        (entry) => {
                            return entry.uri !== "/study/archived-content";
                        }
                    );

                    return { ...section, entries: filteredEntries };
                })
            );
        } else {
            setCollectionSections(sections);
        }
    }, [isClient, showArchivedContent, sections]);

    return (
        <section
            className={classes(
                styles.gospelLibrary,
                activePanel && styles.sidePanelManagerOpen
            )}
        >
            {!crossLinkMode && (
                <Helmet>
                    <title>{title}</title>
                    {noIndex && (
                        <meta key="robots" name="robots" content="noindex" />
                    )}
                    {canonicalUrl && (
                        <link
                            key="canonical"
                            rel="canonical"
                            href={`${APP_URL}${canonicalUrl}`}
                        />
                    )}
                </Helmet>
            )}
            <LibraryHeader
                breadCrumbs={breadCrumbs}
                className={styles.libraryHeader}
                current={title}
            />
            {notifications && <Notifications notifications={notifications} />}
            <Frame
                className={classes(styles.contentWrapper)}
                type="narrowTop"
                id="main"
            >
                {status >= 300 && !sections.length ? (
                    <ErrorPanel status={status} id={"library"} {...props} />
                ) : (
                    <LibraryGridItems
                        crossLinkMode={crossLinkMode}
                        fallbackEntryImage={fallbackEntryImage}
                        lang={lang}
                        sections={collectionSections}
                        className={activePanel && styles.sidePanelOpen}
                    />
                )}
            </Frame>
            <Mask
                active={allowMask && activePanel}
                className={classes(styles.sidePanelMask)}
                onClick={() => toggleSidePanel("")}
            />
            {!crossLinkMode && (
                <SidePanelManager
                    className={styles.sidePanelManager}
                    handleVirtualScroll={
                        scrollUtils.current?.handleVirtualScroll
                    }
                    page="library"
                />
            )}
            <PanelPlatformFooter
                className={styles.libraryFooter}
                forceMobile={activePanel && forceMobile}
            />
        </section>
    );
};

export default memo(
    Library,
    (prevProps, nextProps) => nextProps.library.sections.length === 0
);
