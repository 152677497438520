import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "@churchofjesuschrist/universal-env";
import styles from "./LibraryHeader.css";
import classes from "classnames";

import { setActivePanel } from "../../actions/sidePanel";
import { selectActivePanel } from "../../selectors";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import analytics from "../../../util/analytics";

import {
    BookmarkOutline as BookmarkIcon,
    MoreMenuVertical as KebabIcon,
    Notebook as NotebookIcon,
} from "@churchofjesuschrist/eden-icons";
import { iconButtonFactory } from "../../components/IconButton";
import GeneralContext from "../GeneralContext";
import SmartLink from "../SmartLink";
import StudySetsButton from "../StudySetsButton";

const { STUDY_NOTEBOOK_URL } = get();

const BookmarksButton = iconButtonFactory(BookmarkIcon);
const NotebooksButton = iconButtonFactory(NotebookIcon);
const OptionsButton = iconButtonFactory(KebabIcon);

BookmarksButton.displayName = "BookmarksButton";
NotebooksButton.displayName = "NotebookButton";
OptionsButton.displayName = "OptionsButton";

export const buildToggleSidePanel =
    ({ activePanel, setActivePanel }) =>
    (panel) => {
        activePanel === panel ? setActivePanel(null) : setActivePanel(panel);

        if (panel === "OptionsPanel" && activePanel !== panel) {
            analytics.fireComponentClickEvent({
                component: {
                    info: { name: "options" },
                    category: { primary: "site tools" },
                },
            });
        }

        if (panel === "StudySetsPanel" && activePanel !== panel) {
            analytics.fireStudyToolsEvent({
                component: {
                    info: { name: "study set" },
                    category: { type: "view" },
                },
            });
        }
    };

export const LibraryHeader = ({
    activePanel,
    breadCrumbs = [],
    className,
    crossLinkMode,
    current,
    selectI18nStringById,
    setActivePanel,
}) => {
    const toggleSidePanel = buildToggleSidePanel({
        activePanel,
        setActivePanel,
    });
    let breadCrumbsList = breadCrumbs;
    let breadCrumbsListLength = breadCrumbsList.length - 1;

    // Only add `current` to the list if it isn't already the last in the list
    if (breadCrumbsList[breadCrumbsListLength]?.title !== current) {
        breadCrumbsList = [...breadCrumbsList, { title: current }];
        breadCrumbsListLength++; // update length of list since we added `current` above
    }

    return (
        <div className={classes(className, styles.LibraryHeader)}>
            {breadCrumbs &&
                current &&
                breadCrumbsList.map((item, i) => (
                    <span
                        key={item.title + i}
                        className={classes(
                            styles.link,
                            item.title === current
                                ? styles.inactiveLink
                                : styles.activeLink
                        )}
                    >
                        <SmartLink
                            to={
                                // If the item is not the last one, use the uri
                                i < breadCrumbsListLength && item.uri
                                    ? item.uri
                                    : undefined
                            }
                        >
                            {item.title}
                        </SmartLink>
                    </span>
                ))}
            {!crossLinkMode && (
                <div className={styles.iconGroupEnd}>
                    <NotebooksButton
                        href={STUDY_NOTEBOOK_URL}
                        target="_blank"
                        title={selectI18nStringById("NotebookIconTitleText")}
                        data-testid="notes-app"
                    />
                    <span className={styles.pipe}></span>
                    <StudySetsButton
                        className={
                            activePanel === "StudySetsPanel" &&
                            styles.activePanel
                        }
                        onClick={() => toggleSidePanel("StudySetsPanel")}
                        testId="study-set-tab"
                    />
                    <BookmarksButton
                        className={
                            activePanel === "BookmarksPanel" &&
                            styles.activePanel
                        }
                        onClick={() => toggleSidePanel("BookmarksPanel")}
                        title={selectI18nStringById("bookmarkManagerTitle")}
                        data-testid="bookmarks-tab"
                    />
                    <OptionsButton
                        className={
                            activePanel === "OptionsPanel" && styles.activePanel
                        }
                        onClick={() => toggleSidePanel("OptionsPanel")}
                        title={selectI18nStringById("optionsTitle")}
                        data-testid="options-tab"
                    />
                </div>
            )}
        </div>
    );
};

const LibraryHeaderContainer = (props) => {
    const dispatch = useDispatch();
    const activePanel = useSelector(selectActivePanel);
    const selectI18nStringById = useSelectI18nStringById();
    const { crossLinkMode } = useContext(GeneralContext);

    return (
        <LibraryHeader
            activePanel={activePanel}
            crossLinkMode={crossLinkMode}
            selectI18nStringById={selectI18nStringById}
            setActivePanel={(value) => dispatch(setActivePanel(value))}
            {...props}
        />
    );
};

export default LibraryHeaderContainer;
